import React, { useState } from 'react';
import './Faq.css'; // Import the CSS file for styling

export default function Faq() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "WHAT SERVICES DOES SKYLAND WEB OFFER?",
      answer:
        "At Skyland Web, we specialize in custom software development, web application development, mobile app development, cloud solutions, and IT consultancy services tailored to your business needs.",
    },
    {
      question: "HOW DO I REQUEST A CONSULTATION FOR A CUSTOM PROJECT?",
      answer:
        "Simply visit our Contact Us page and fill out the inquiry form. Our team will get back to you within 24 hours to discuss your project requirements.",
    },
    {
      question: "CAN SKYLAND WEB INTEGRATE THIRD-PARTY APIS INTO MY APPLICATION?",
      answer:
        "Absolutely! Our team has extensive experience in API integration, ensuring seamless functionality between your application and external services.",
    },
    {
      question: "DOES SKYLAND WEB PROVIDE ONGOING SUPPORT AND MAINTENANCE?",
      answer:
        "Yes, we offer comprehensive support and maintenance services to ensure your software runs smoothly and remains up-to-date.",
    },
    {
      question: "HOW LONG DOES IT TAKE TO COMPLETE A SOFTWARE PROJECT?",
      answer:
        "Project timelines vary based on complexity and scope. We’ll provide an estimated timeline after our initial consultation.",
    },
    {
      question: "WHAT INDUSTRIES DOES SKYLAND WEB WORK WITH?",
      answer:
        "We cater to various industries, including finance, healthcare, real estate, retail, and more. Our solutions are designed to meet the unique needs of each industry.",
    },
    {
      question: "HOW DO I UPGRADE MY SERVICE PLAN OR REQUEST ADDITIONAL FEATURES?",
      answer:
        "Contact our support team through support@skylandweb.com, and we’ll assist you with upgrades or feature enhancements.",
    },
    {
      question: "DOES SKYLAND WEB OFFER MOBILE APP DEVELOPMENT?",
      answer:
        "Yes, we develop feature-rich mobile applications for iOS, Android, and cross-platform solutions to help your business reach a wider audience.",
    },
    {
      question: "HOW DO I GET IN TOUCH WITH SKYLAND WEB’S SUPPORT TEAM?",
      answer:
        "You can reach us via our Contact Us page, email us at support@skylandweb.com, or call us at our support hotline.",
    },
    {
      question: "IS MY DATA SECURE WITH SKYLAND WEB?",
      answer:
        "Absolutely. Data security is our top priority. We follow industry best practices and use the latest technologies to safeguard your information.",
    },
  ];
  

  return (
    <div id='faq' className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="faq-items-container">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? 'open' : ''}`}
            onClick={() => toggleFaq(index)}
          >
            <h3 className="faq-question">
              {faq.question} <span>{openIndex === index ? '-' : '+'}</span>
            </h3>
            {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
}
